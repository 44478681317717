export const environment: any = {
  "production": true,
  "API": "https://api.onlineexpeditors.com/api/",
  "client_url": "https://my.govworks.com",
  "visas": false,
  "header": {
    "logo": "assets/svg/onlineexpeditors.svg"
  },
  "support": {
    "phone": "8889700069",
    "email": "info@rushmypassport.com",
    "label": ""
  },
  "login": {
    "enabled": false,
    "logo": "assets/svg/onlineexpeditors.svg"
  },
  "legal_links": {
    "terms": "https://www.onlineexpeditors.com/terms-of-use",
    "privacy": "https://www.onlineexpeditors.com/privacy-policy",
    "refund": "https://www.onlineexpeditors.com/refund-policy"
  },
  "source": {
    "domain": "onlineexpeditors",
    "main_website": "https://www.onlineexpeditors.com/",
    "promo_code": true,
    "name": "OnlineExpeditors"
  },
  "footer": {
    "logo": "assets/svg/onlineexpeditors_dark.svg",
    "logo_link": "https://www.onlineexpeditors.com/",
    "copyright": "OnlineExpeditors.com, LLC"
  },
  "landing_pages": {
    "passport": {
      "enabled": false
    },
    "visa": {
      "enabled": false
    },
    "root_type": "passport"
  },
  "fedex_logo": true,
  "create_lead": true, 
  "seals": {
    "truste": "320db9df-ebf6-47ab-9655-f870d5b28c42"
  },
  "summary_cta_icon": true,
  "livechat": {
    "enabled": true,
    "license_id": "1073051",
    "group": 18
  },
  "tags": {
    "analytics": "UA-151786943-1",
    "tag_manager": "GTM-KMZXQM4",
    "adwords": "AW-696968689",
    "gtag_conv": "oeVxCLzGprMBEPHLq8wC"
  },
  "locations_page": {
    "enabled": false
  }
};